import { Icon, IconProps } from '@chakra-ui/react';
import React, { FC } from 'react';

const WalletConnectIcon: FC<IconProps> = (props: IconProps) => (
  <Icon viewBox="0 0 400 400" {...props}>
    <path
      fill="currentColor"
      d="M 68.502129,278.55645 C 3.4981391,215.12224 0,211.51576 0,207.93287 0,203.62839 27.524685,176 31.813015,176 c 3.208785,0 28.61144,23.42385 63.502456,58.55577 10.256839,10.32766 19.461179,18.77756 20.454089,18.77756 0.99291,0 19.18224,-16.95 40.42073,-37.66666 21.2385,-20.71667 39.78416,-38.20836 41.21258,-38.87042 1.54504,-0.71612 3.64922,-0.71612 5.19426,0 1.42842,0.66206 19.97408,18.15375 41.21258,38.87042 21.23849,20.71666 39.42782,37.66666 40.42073,37.66666 0.99291,0 10.19725,-8.4499 20.45409,-18.77756 C 339.57555,199.42385 364.9782,176 368.18699,176 372.47532,176 400,203.62839 400,207.93287 c 0,3.58289 -3.49813,7.18937 -68.50213,70.62358 -37.34036,36.43863 -44.41427,42.77688 -47.74187,42.77688 -3.32491,0 -9.76632,-5.7559 -43.27176,-38.66666 C 218.83329,261.4 200.61539,244 200,244 c -0.61539,0 -18.83329,17.4 -40.48424,38.66667 -33.50544,32.91076 -39.94686,38.66666 -43.27176,38.66666 -3.32759,0 -10.40151,-6.33825 -47.741871,-42.77688 z M 85.91774,157 C 74.522031,145.79752 70.659977,141.15637 70.662837,138.66771 70.671655,130.99463 104.42875,103.55812 126.98819,92.888563 139.13876,87.141911 158.96041,80.823128 172,78.539616 c 12.85933,-2.251948 43.14067,-2.251948 56,0 32.80705,5.745223 64.58497,21.754834 89.26357,44.970684 15.07596,14.18238 15.04566,14.41667 -4.24385,32.81721 -8.26085,7.88013 -15.82408,14.33013 -16.8072,14.33333 -0.98311,0.003 -6.38311,-4.24536 -12,-9.44125 C 258.33009,137.27707 232.47037,126.66667 200,126.66667 c -32.81085,0 -58.50951,10.66022 -84.87565,35.20781 C 109.14274,167.44352 103.55745,172 102.71259,172 c -0.84487,0 -8.402547,-6.75 -16.79485,-15 z"
    />
  </Icon>
);

export default WalletConnectIcon;
